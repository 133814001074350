import { EventType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { loginRequest } from "../authConfig";

/**
 * Houses the handling of the global events
 */
const useMsalEvents = () => {
  const { instance } = useMsal();

  const [error, setError] = useState();

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  useEffect(() => {
    const errorHandlerCallbackId = instance.addEventCallback((message: any) => {
      if (message.eventType === EventType.LOGIN_FAILURE) {
        if (message.error) {
          instance.loginRedirect(loginRequest);
          return;
        }
      }

      if (message.error) {
        setError(message.error);
      }
    });

    return () => {
      if (errorHandlerCallbackId) {
        instance.removeEventCallback(errorHandlerCallbackId);
      }
    };
  }, [instance]);

  return {
    error,
  };
};

export default useMsalEvents;
