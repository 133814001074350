import { useEffect, useState } from "react";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { AccountInfo, InteractionType } from "@azure/msal-browser";

import B2BLoading from "./app/B2BLoading";
import Content from "./components/Content/Content";
import useMsalEvents from "./hooks/useMsalEvents";
import { loginRequest } from "./authConfig";
import InterviewSelect from "./components/InterviewSelect";

const MsalSwitcher = () => {
  const [authorised, setAuthorised] = useState(false);
  const { instance, accounts } = useMsal();
  useMsalEvents();

  useEffect(() => {
    if (accounts.length) {
      const account: AccountInfo = accounts[0];
      setAuthorised(true);
    }
  }, [accounts, instance]);

  const ErrorComponent = ({ error }: any) => {
    return <p>An Error Occurred: {error}</p>;
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={{
        ...loginRequest,
      }}
      errorComponent={ErrorComponent}
      loadingComponent={B2BLoading}
    >
      {authorised && (
        <Content>
          <InterviewSelect />
        </Content>
      )}
    </MsalAuthenticationTemplate>
  );
};

export default MsalSwitcher;
