import { faClipboardQuestion, faFireFlameCurved, faForwardStep } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Row } from "react-bootstrap";

const { REACT_APP_ENABLE_HEATMAP } = process.env;
const enableHeatmap = REACT_APP_ENABLE_HEATMAP === "true";


interface IDefaultSelectProps {
    interviewCallback: () => void,
    skipToStudyCallback: () => void,
    heatmapCallback: () => void,
}


const DefaultSelect = ({interviewCallback, skipToStudyCallback, heatmapCallback} : IDefaultSelectProps) => (
    <Container className='mt-3'>
        <Row>
            <Col>
            <Button 
                onClick={interviewCallback}
                type="button"
                variant="outline-primary"
                className='w-100'>
                <div className="p-3">
                    <FontAwesomeIcon icon={faClipboardQuestion} size="10x" fixedWidth />
                </div>
                <span style={ { fontSize: "2rem" }}>Interview Process</span>
            </Button>
            </Col>
            <Col>
            <Button 
                onClick={skipToStudyCallback}
                type="button"
                variant="outline-primary"
                className='w-100'>
                <div className="p-3">
                    <FontAwesomeIcon icon={faForwardStep} size="10x" fixedWidth />
                </div>
                <span style={ { fontSize: "2rem" }}>Skip to study</span>
            </Button>
            </Col>
            {enableHeatmap && (
                <Col>
                    <Button 
                        onClick={heatmapCallback}
                        type="button"
                        variant="outline-primary"
                        className='w-100'>
                        <div className="p-3">
                            <FontAwesomeIcon icon={faFireFlameCurved} size="10x" fixedWidth />
                        </div>
                        <span style={ { fontSize: "2rem" }}>Grey Asset Heatmap</span>
                    </Button>
                </Col>
            )}
            
        </Row>
    </Container>
);

export default DefaultSelect;