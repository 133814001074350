import { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Navbar from "react-bootstrap/Navbar";
import NavItem from "react-bootstrap/NavItem";
import { useMsal } from "@azure/msal-react";

const Profile = () => {
  const { accounts, instance } = useMsal();
  const [name, setName] = useState<string>();

  useEffect(() => {
    if (accounts.length > 0) {
      setName(accounts[0].username);
    }
  }, [accounts]);

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  if (name) {
    return (
      <Dropdown as={NavItem} alignRight>
        <Dropdown.Toggle as="div">
          <Navbar.Text className="nien_logout_menu">
            {name} <i className="icon-user-solid"></i>
          </Navbar.Text>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-2">
          <Dropdown.Item onClick={() => handleLogout()}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  } else {
    return null;
  }
};
export default Profile;
