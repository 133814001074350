import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import EALogo from "./EALogo";
import Profile from "./Profile";

interface IEANavbar {
  project: string;
  version: string;
  color: "light" | "dark" | undefined;
  bgColor: string;
}

const EANavbar = ({ project, version, color, bgColor }: IEANavbar) => {
  const { REACT_APP_B2B } = process.env;
  const b2b = REACT_APP_B2B === "true";

  return (
    <Navbar bg={bgColor} variant={color}>
      <Navbar.Brand className="me-auto">
        <EALogo project={project} width="360" height="30" />
        <span
            style={{ height: 19, marginTop: 5 }}
            className="badge badge-danger text-uppercase"
          >
            v{version}
          </span>
      </Navbar.Brand>
      <Nav>{b2b && <Profile />}</Nav>
    </Navbar>
  );
};

export default EANavbar;
