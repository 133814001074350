import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bsCustomFileInput from "bs-custom-file-input";
import { ChangeEvent, memo, useEffect, useRef, useState } from "react";
import { formValidation } from "../../lib/@form-validation/esm/bundle/full";
import { Bootstrap5 } from "../../lib/@form-validation/esm/plugin-bootstrap5";
import { Trigger } from "../../lib/@form-validation/esm/plugin-trigger";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Tab,
  Table,
  Tabs
} from "react-bootstrap";

import {
  resetToInitialState,
  selectApp,
  updateInputsProp,
  updateMeterTypeTab,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { isEmpty } from "../../utils/isEmpty";
import "./react-tags-override.css";
import { meterType } from "../../models/models"

import { DwellingTypes, IPostData, Phases, PropertyTypes, } from "eatl.connectlite.nien.web.userinterview.component";

import jsPDF from "jspdf";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import budgetHeader from "../../img/budget-header.png";
import { userInterviewService } from "../../services/userInterviewService";

import { IToast, apiErrorMessageToToast, apiErrorMessagesToToast, fatalApiErrorToast } from "../ErrorHandling/ToastHandler";
import priceBookItemColumnDefinitions from "../../models/priceBookItemColumnDefinitions";
import RestartButton from "../RestartButton";
import useLocalStorage from "../../hooks/useLocalStorageWrapper";

const { REACT_APP_MAP_HOST: maphost } = process.env;


export interface IStudyFormProps {
  InterviewData: IPostData | null,
  InterviewParams: any,
  SaveInterviewData(data: IPostData): Promise<IPostData | null>,
  ReplaceToastCallback(toast: IToast[]): void,
  AppendToastCallback(toastToAppend: IToast[]): void,
  HasInterview: boolean,
  ResetCallback: () => void,
}

interface IStudyFormState {
  screenshot: string,
  quoteRef: string,
  quoteDateTime: string,
  postcode: string,
  priceBookName: string,
  totalCost: string,
  contestableTotalCost: string,
  nonContestableTotalCost: string,
  contestablePriceBookItems: any[],
  nonContestablePriceBookItems: any[],
  activityBasedPricing: string,
  buildingOverride: boolean
}

const initialState: IStudyFormState = {
  screenshot: "",
  quoteRef: "",
  quoteDateTime: "",
  postcode: "",
  priceBookName: "",
  totalCost: "",
  contestableTotalCost: "",
  nonContestableTotalCost: "",
  contestablePriceBookItems: [],
  nonContestablePriceBookItems: [],
  activityBasedPricing: "",
  buildingOverride: false
}

const StudyForm = (props: IStudyFormProps) => {
  const app = useAppSelector(selectApp);
  const { inputs } = app;
  const [state, setState] = useLocalStorage<IStudyFormState>("ConnectLite.Internal.StudyForm.state", initialState);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const fvRef = useRef(null);

  useEffect(() => {
    bsCustomFileInput.init();
    //console.log(inputs.multiSwitchConnections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    window.addEventListener("message", handleEvent, false);

    return () => {
      window.removeEventListener("message", handleEvent, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkSetInterviewData();
  }, []);

  useEffect(() => {
    if (inputs.quotationRef !== "") {
      setFrame();
    }
  }, [inputs.quotationRef]);

  useEffect(() => {
    const form = document.getElementById("StudyForm");

    if (form) {
      if (!fvRef.current) {
        fvRef.current = formValidation(form, {
          fields: {
            singlePhaseConnections: {
              validators: {
                notEmpty: {
                  message: "Connections required"
                },
                greaterThan: {
                  min: 0,
                  inclusive: true,
                  message: 'Minimum 0 connections'
                },
                lessThan: {
                  max: props.InterviewParams?.MaxDomestic ?? 4,
                  inclusive: true,
                  message: 'Maximum 4 connections'
                }
              }
            },
            threePhaseConnections: {
              validators: {
                notEmpty: {
                  message: "Connections required"
                },
                greaterThan: {
                  min: 0,
                  inclusive: true,
                  message: "Minimum 0 connections"
                },
                lessThan: {
                  max: 1,
                  inclusive: true,
                  message: "Maximum 1 connection"
                }
              }
            },
            multiSwitchConnections: {
              validators: {notEmpty: {
                message: "Apartments required"
              },
              greaterThan: {
                min: 0,
                inclusive: true,
                message: 'Minimum 0 apartments'
              },
                lessThan: {
                  max: props.InterviewParams?.MaxApartments ?? 5,
                  inclusive: true,
                  message: `Maximum ${props.InterviewParams?.MaxApartments} apartments`
                }
              }
            },
            totalkVA: {
              validators: {
                callback: {
                  callback: validateTotalKva.bind(this)
                }
              },
            },
            searchValue: {
              validators: {
                notEmpty: {
                  message: "Please enter a postcode"
                },
                zipCode: {
                  country: "GB",
                  message: "Please enter a valid postcode"
                },
                regexp: {
                  enabled: stringIsNotNullUndefinedOrEmpty(props.InterviewParams.PostcodeValidationRegExpPattern),
                  regexp: props.InterviewParams.PostcodeValidationRegExpPattern ?? '[\s\S]*',
                  flags: 'i',
                  message: props.InterviewParams.PostcodeValidationRegExpMessage ?? 'Please enter a postcode serviced by Northern Ireland Electricity Networks'
                },
                remote: {
                  enabled: stringIsNotNullUndefinedOrEmpty(props.InterviewParams.PostcodeValidationRemoteEndpoint),
                  url: props.InterviewParams.PostcodeValidationRemoteEndpoint,
                  method: props.InterviewParams.PostcodeValidationRemoteMethod ?? 'GET',
                  crossDomain: props.InterviewParams.PostcodeValidationRemoteCrossDomain ?? true,
                  message: props.InterviewParams.PostcodeValidationRemoteMessage ?? 'Please enter a postcode serviced by Northern Ireland Electricity Networks'
                }
              }
            }
          },
          plugins: {
            trigger: new Trigger({}),
            bootstrap5: new Bootstrap5({
              rowSelector: ".form-group"
            })
          }
        });
      } else {
        // rebind to get the updated state on inputs properties
        const fv = fvRef.current as any;
        fv.updateValidatorOption(
          "totalkVA",
          "callback",
          "callback",
          validateTotalKva.bind(this)
        );
      }
    }

  }, [inputs]);



  const resetAndTriggerCallback = async () => {
    resetResponse();
    setSubmitting(false);
    dispatch(resetToInitialState());

    if (props.ResetCallback) {
      props.ResetCallback();
    }
  }


  function validateTotalKva(input: any): any {
    let validdata = {
      valid: false,
      message: ""
    };

    if (input?.value && input.value !== "") {

      const val: number = parseFloat(input.value);

      const tpc: number = inputs.threePhaseConnections ?? 0;
      const spc: number = inputs.singlePhaseConnections ?? 0;
      const apts: number = (inputs.multiSwitchConnections.length > 0) ? Number(inputs.multiSwitchConnections[0]) : 0;
      const maxtp: number = tpc * (props.InterviewParams?.MaxCommercialKVA ?? 69);
      const maxsp: number = spc * (props.InterviewParams?.MaxCommercialSingleKVA ?? 23);
      const maxapts: number = (apts * (props.InterviewParams?.MaxDomesticKVA ?? 23)) + (props.InterviewParams?.MaxApartmentsKVA ?? 69);
      const maxdom: number = maxtp + maxsp;
      const totalmax: number = props.InterviewParams?.MaxTotalKVA ?? 256;
      if (val <= 0) {
        validdata.valid = false;
        validdata.message = 'Must be greater than 0 kVA';
      }
      else {
        if (val > totalmax) {
          validdata.valid = false;
          validdata.message = `Maximum ${totalmax} kVA`;
        } else {
          if (apts > 0) {
            if (val > maxapts) {
              validdata.valid = false;
              validdata.message = `Maximum ${maxapts} kVA`;
            } else {
              validdata.valid = true;
            }
          } else {
            if (input.value > maxdom) {
              validdata.valid = false;
              validdata.message = `Maximum ${maxdom} kVA`;
            } else {
              validdata.valid = true;
            }
          }
        }
      }
    } else {
      validdata.valid = false;
      validdata.message = "Total kVA required";
    }
    return validdata;
  };

  function stringIsNotNullUndefinedOrEmpty(value: string | undefined | null): boolean {
    return (value !== null
      && value !== undefined
      && value !== '');
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    type: string = ""
  ): void => {
    const target = event.target;
    const value = type
      ? event
      : target.type === "number"
        ? target.valueAsNumber
        : target.value;
    const name = type ? type : target.name;
    dispatch(updateInputsProp([{ key: name, value }]));
  };

  const handleMultiSwitchChange = (
    event: ChangeEvent<HTMLInputElement>,
    type: string = ""
  ): void => {
    const target = event.target;
    const value = target.valueAsNumber;

    dispatch(updateInputsProp([{ key: 'multiSwitchConnections', value: (value > 0) ? [`${value}`] : ['0'] }]));
  }

  const handleInputToggle = (name: string = ""): void => {
    const value = (inputs as any)[name] ? false : true;
    dispatch(updateInputsProp([{ key: name, value }]));
  };

  const resetResponse = () => {
    setState(initialState);
  };

  const handleSubmit = (event: React.SyntheticEvent) => {

    event.preventDefault();
    event.stopPropagation();

    if (fvRef?.current) {
      (fvRef.current as any).validate().then((result: string) => {
        if (result === "Valid") {
          setSubmitting(true);
          props.ReplaceToastCallback?.([]);

          userInterviewService.saveStudy(inputs)
            .then((res: any) => {

              if (res?.data?.quoteRef) {
                if (res.data.quoteRef !== inputs.quotationRef) {
                  dispatch(
                    updateInputsProp([{ key: "quotationRef", value: res.data.quoteRef }])
                  );
                } else {
                  setFrame();
                }
              }
            })
            .catch((err: any) => {
              console.log(err);
              if (err.response && err.response.status === 400 && err.response.data?.Messages) {
                props.ReplaceToastCallback?.(apiErrorMessagesToToast(err.response.data.Messages as string[]));
              }
              else if (err.message) {
                props.ReplaceToastCallback?.([fatalApiErrorToast] as IToast[]);
              }
            })
            .finally(() => setSubmitting(false));
        }
      });
    }
  };

  const setFrame = () => {
    let frame = document.getElementById("connectlite");
    if (isIFrame(frame) && frame.contentWindow) {
      //console.log("Sending inputs to frame");
      //console.log(inputs);
      frame.contentWindow.postMessage(inputs, "*");
      resetResponse();
    }
  }

  const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === "IFRAME";

  const handleSearchType = (value: string) => {
    if (inputs.searchType === value) return;
    dispatch(
      updateInputsProp([
        { key: "searchType", value },
        { key: "searchValue", value: "" },
      ])
    );
  };

  const importNetwork = (e: React.ChangeEvent<HTMLInputElement>): any => {
    if (e.target.files === null) {
      throw new Error("Error finding e.target.files");
    }

    const fr = new FileReader();

    fr.onloadend = () => {
      const result = JSON.parse(fr.result as string);
      dispatch(updateInputsProp([{ key: "existingStudy", value: result }]));
    };

    fr.readAsText(e.target.files[0]!);
  };

  const handleEvent = (e: any) => {
    //console.log(e.data.message ?? "No message in data");
    const data = e.data.message;
    if (data) {
      resetResponse();
      const parsedData = JSON.parse(data);


      const hasInputs = !isEmpty(parsedData.inputs);
      const hasBillOfMaterials = !isEmpty(parsedData.billOfMaterials);

      setState({
        ...state,
        quoteRef: hasInputs ? parsedData.inputs.quotationRef : state.quoteRef,
        quoteDateTime: hasInputs ? parsedData.inputs.quotationDateTime : state.quoteDateTime,
        postcode: hasInputs ? parsedData.inputs.searchValue : state.postcode,
        screenshot: hasBillOfMaterials ? parsedData.billOfMaterials.screenshot : state.screenshot,
        priceBookName: hasBillOfMaterials ? parsedData.billOfMaterials.priceBookName : state.priceBookName,
        totalCost: hasBillOfMaterials ? parsedData.billOfMaterials.totalCost : state.totalCost,
        contestableTotalCost: hasBillOfMaterials ? parsedData.billOfMaterials.contestableTotalCost : state.contestableTotalCost,
        nonContestableTotalCost: hasBillOfMaterials ? parsedData.billOfMaterials.nonContestableTotalCost : state.nonContestableTotalCost,
        contestablePriceBookItems: hasBillOfMaterials ? parsedData.billOfMaterials.items.filter((x: any) => x.contestableType == 1) : state.contestablePriceBookItems,
        nonContestablePriceBookItems: hasBillOfMaterials ? parsedData.billOfMaterials.items.filter((x: any) => x.contestableType == 0) : state.nonContestablePriceBookItems,
        activityBasedPricing: hasBillOfMaterials ? parsedData.billOfMaterials.activityBasedPricing : state.activityBasedPricing,
        buildingOverride: hasInputs ? parsedData.inputs.buildingOverride : state.buildingOverride
      });

    }
    if (e.data?.invalidPostCode) {
      props.AppendToastCallback?.([apiErrorMessageToToast(`"${e.data?.invalidPostCode}" is not a valid postcode`)]);
      setFrame();
    }
  };

  const getMultiSwitchTags = () => {
    if (!inputs.multiSwitchConnections) {
      return [];
    }

    return inputs.multiSwitchConnections.map((x) => {
      return { id: x, text: x };
    });
  };

  const addMultiSwitch = (tag: any) => {
    dispatch(
      updateInputsProp([
        {
          key: "multiSwitchConnections",
          value: [...inputs.multiSwitchConnections, tag.text],
        },
      ])
    );
  };

  const deleteMultiSwitch = (i: number) => {
    const newValue = inputs.multiSwitchConnections.filter(
      (tag, index) => index !== i
    );
    dispatch(
      updateInputsProp([
        {
          key: "multiSwitchConnections",
          value: newValue,
        },
      ])
    );
  };

  const changeMeterTab = (k: string | null) => {
    if (k) {
      // ------------------------------------------------------------
      // AGW don't seem to be updating this anywhere so updating here
      // ------------------------------------------------------------
      if (props.InterviewData && k === "ct-meter-connections") {
        props.InterviewData.propertyType = 2;
      }
      // ------------------------------------------------------------
      dispatch(updateMeterTypeTab(k as meterType));

      // ------------------------------------------------------------
      // AGW also set a default of 3phase as no UI shows to do this
      // ------------------------------------------------------------
      dispatch(
        updateInputsProp([{ key: "threePhaseConnections", value: 1 }])
      );
      // ------------------------------------------------------------
    }
  };

  const getConnections = () => {
    let value = 0;
    if (props.InterviewData) {
      if (props.InterviewData.propertyType === PropertyTypes.Commercial) {
        value = props.InterviewData.singlePhaseConnections > 0 ? props.InterviewData.singlePhaseConnections : props.InterviewData.threePhaseConnections > 0 ? props.InterviewData.threePhaseConnections : 1;
      }

    }
    return value;
  }

  const getMultiSwitchConnections = () => {
    let value: string[] = [];
    if (props.InterviewData) {
      if (props.InterviewData.propertyType === PropertyTypes.Domestic) {
        if (props.InterviewData.dwellingType === DwellingTypes.ApartmentBlock) {
          value = (props.InterviewData.apartments.length > 0) ? [`${props.InterviewData.apartments.length}`] : [];
        }
      }

    }
    return value;

  }
  const getMeterType: () => meterType = () => {
    let value: meterType = "wc-meter-connections";

    if (props.InterviewData) {
      if (props.InterviewData.propertyType === PropertyTypes.Domestic) {
        if (props.InterviewData.dwellingType === DwellingTypes.ApartmentBlock) {
          value = "ms-meter-connections";
        }
      } else if (props.InterviewData.propertyType === PropertyTypes.Commercial) {
        value = "ct-meter-connections";
      }
    }
    return value;
  }

  const getSinglePhase = () => {
    let total: number = 0;

    if (props.InterviewData) {
      if (props.InterviewData.propertyType === PropertyTypes.Commercial) {
        if (props.InterviewData.phase === Phases.Single) {
          total = 1;
        }
      } else if (props.InterviewData.propertyType === PropertyTypes.Domestic) {
        if (props.InterviewData.dwellingType === DwellingTypes.Domestic) {
          total += props.InterviewData.domesticDwellings?.length ?? 0;
        }
      }
    }

    if (total === 0) {
      total = inputs.singlePhaseConnections;
    }
    return total;
  };

  const getThreePhase = () => {
    let total: number = 0;

    if (props.InterviewData) {
      if (props.InterviewData.propertyType === PropertyTypes.Commercial) {
        if (props.InterviewData.phase === Phases.Three) {
          total = 1;
        }
      }
    }

    if (total === 0) {
      total = inputs.threePhaseConnections;
    }
    return total;
  };

  const checkSetInterviewData = () => {
    if (props.HasInterview && props.InterviewData) {
      //console.log("Interview data");
      //console.log(inputdata?.InterviewData);

      // console.log("Updting quoteref to '" + inputdata?.InterviewData.interviewId + "'");
      dispatch(
        updateInputsProp([
          { key: "quotationRef", value: props.InterviewData.interviewId ?? "" },
          { key: "searchType", value: "Postcode" },
          { key: "searchValue", value: props.InterviewData.postcode ?? "" },
          { key: "totalkVA", value: props.InterviewData?.totalKVA ?? 0 },
          { key: "singlePhaseConnections", value: getSinglePhase() },
          { key: "threePhaseConnections", value: getThreePhase() },
          { key: "connections", value: getConnections() },
          { key: "multiSwitchConnections", value: getMultiSwitchConnections() },
          { key: "landlordMeterPhase", value: props.InterviewData.landlordMeter?.phase },
          { key: "meterType", value: getMeterType() }
        ])
      );
    } else {
      // change the test to passed in param
      //console.log("skipped interview");
      dispatch(
        updateInputsProp([
          { key: "singlePhaseConnections", value: getSinglePhase() },
          { key: "threePhaseConnections", value: getThreePhase() },
          { key: "connections", value: getConnections() },
          { key: "meterType", value: getMeterType() }
        ])
      );
    }
  };

  const [fileName, setFileName] = useState("NEW CONNECTION – BUDGETARY ESTIMATE");
  const [loadingPDF, setLoadingPDF] = useState(false);

  

  const exportPDF = () => {



    setLoadingPDF(true);


    const pdf = new jsPDF({
      format: "a4",
      unit: "px",
      orientation: "p",
    });

    const header = document.getElementById('budget-header') as HTMLElement;
    header.style.display = "block";

    const content = document.getElementById('content') as HTMLElement;

    const container = content.parentElement as HTMLElement;

    container.style.transformOrigin = 'top left';
    container.style.transform = `scale(${1 / window.devicePixelRatio})`;

    const subContent = document.getElementById('sub-content') as HTMLElement;
    subContent.style.transformOrigin = "top";
    subContent.style.transform = `scale(0.95)`; // Alternative to setting margin - doesnt affect available width for BoM

    const bootstrapContainerXLMaxWidth = 1140;

    if (container.clientWidth < bootstrapContainerXLMaxWidth) {
      container.style.maxWidth = `${bootstrapContainerXLMaxWidth}px`;
      container.style.width = `${bootstrapContainerXLMaxWidth}px`;
    }


    const pageWidthPixels = pdf.internal.pageSize.width;
    const contentScrollWidthPixels = content.scrollWidth;
    const scale = pageWidthPixels / contentScrollWidthPixels;

    const pageHeightPixels = pdf.internal.pageSize.height;

    const contentTop = content.getBoundingClientRect().top + window.scrollY;

    const marginTop = 20;
    const marginBottom = 30;
    const marginLeft = 0;
    const marginRight = 0;

    const yMargin = (marginTop + marginBottom);

    const yAdjust = (0 - contentTop);

    const scaledUpPageHeightPixels = ((pageHeightPixels - yMargin) / scale);

    const networkMapHeaderDiv = document.getElementById('networkMapHeaderDiv') as HTMLElement;
    const networkMapImage = document.getElementById('networkMapImage') as HTMLElement;

    pushToNextPage(networkMapHeaderDiv, networkMapImage, yAdjust, scaledUpPageHeightPixels);
    
    const nonContestableHeaderDiv = document.getElementById('nonContestableHeaderDiv') as HTMLElement;
    if (nonContestableHeaderDiv !== null) {
      const nonContestableBillOfMaterialsTableFirstRow = document.querySelector('#nien_noncontestable_billofmaterials_table tbody tr') as HTMLElement;
      
      pushToNextPage(nonContestableHeaderDiv, nonContestableBillOfMaterialsTableFirstRow, yAdjust, scaledUpPageHeightPixels);
  
      const nonContestableBillOfMaterialsTable = document.getElementById('nien_noncontestable_billofmaterials_table') as HTMLElement;
  
      pushElementWithTableToNextPage(nonContestableHeaderDiv, nonContestableBillOfMaterialsTable, yAdjust, scaledUpPageHeightPixels);
      processBOMTable(nonContestableBillOfMaterialsTable, yAdjust, scaledUpPageHeightPixels);     

    }


    const activityBasedPricingHeaderDiv = document.getElementById('activityBasedPricingHeaderDiv') as HTMLElement;    
    const activityBasedPricingValueDiv = document.getElementById('activityBasedPricingValueDiv') as HTMLElement;

    pushToNextPage(activityBasedPricingHeaderDiv, activityBasedPricingValueDiv, yAdjust, scaledUpPageHeightPixels);
  
    const contestableHeaderDiv = document.getElementById('contestableHeaderDiv') as HTMLElement;
    if (contestableHeaderDiv !== null) {

      const contestableBillOfMaterialsTable = document.getElementById('nien_contestable_billofmaterials_table') as HTMLElement;
      pushElementWithTableToNextPage(contestableHeaderDiv, contestableBillOfMaterialsTable, yAdjust, scaledUpPageHeightPixels);

      processBOMTable(contestableBillOfMaterialsTable , yAdjust, scaledUpPageHeightPixels);  

    }
    

 
    const disclaimerText = document.getElementById('nien_maptext') as HTMLElement;

    const disclaimerTextBottom = scaleForWindowDevicePixelRatio(disclaimerText.getBoundingClientRect().bottom + window.scrollY + yAdjust);

    const pagesWithContent = Math.ceil(disclaimerTextBottom / scaledUpPageHeightPixels);

    pdf.html(content, {
      //margin is ignored? https://github.com/parallax/jsPDF/issues/2924
      margin: [marginTop, marginLeft, marginBottom, marginRight],
      autoPaging: 'text',
      html2canvas: {
        scale: scale,
      },
      callback: function () {
        // need to delete some blank pages first! 
        var pages = pdf.getNumberOfPages();
        //console.log("Total pdf pages " + pages);
        if (pages > pagesWithContent) {
          for (let j = pages; j > pagesWithContent; j--) {
            pdf.deletePage(j);
            //console.log("Deleted blank page " + j);
          }
        }

        // add page numbers
        pages = pdf.getNumberOfPages();
        if (pages > 1) {
          const pageWidth = pdf.internal.pageSize.width;  //Optional
          const pageHeight = pdf.internal.pageSize.height;  //Optional
          pdf.setFontSize(7);  //Optional
          for (let j = 1; j <= pages; j++) {
            let horizontalPos = pageWidth / 2;  //Can be fixed number
            let verticalPos = pageHeight - 10;  //Can be fixed number
            pdf.setPage(j);
            pdf.text(`Page ${j} of ${pages}`, horizontalPos, verticalPos, {
              align: 'center'  //Optional text styling});
            })
          }
        }

        //window.open(pdf.output('bloburl'));
        pdf.save(`${fileName} - ${state.quoteRef}.pdf`);

        header.style.display = "none";
        subContent.style.transformOrigin = "";
        subContent.style.transform = "";
        networkMapHeaderDiv.style.paddingTop = '';
        nonContestableHeaderDiv.style.paddingTop = '';
        contestableHeaderDiv.style.paddingTop = '';
        activityBasedPricingHeaderDiv.style.paddingTop = '';
        container.style.maxWidth = '';
        container.style.width = '';

        container.style.transformOrigin = '';
        container.style.transform = '';

        rejoinBOMTable('nien_contestable_billofmaterials_table');
        rejoinBOMTable('nien_noncontestable_billofmaterials_table');

        setLoadingPDF(false);
      }
    });

  };

  const pushToNextPage = (headerDiv: HTMLElement, lastElem: HTMLElement, yAdjust : number, scaledUpPageHeightPixels: number) => {
    const headerDivTop = scaleForWindowDevicePixelRatio(headerDiv.getBoundingClientRect().top + window.scrollY + yAdjust);

    const headerDivTopPage = Math.floor(headerDivTop / scaledUpPageHeightPixels);
    
    const lastElemBottom = scaleForWindowDevicePixelRatio(lastElem.getBoundingClientRect().bottom + window.scrollY + yAdjust);

    const lastElemBottomPage = Math.floor(lastElemBottom / scaledUpPageHeightPixels);

    if (headerDivTopPage < lastElemBottomPage) {
      headerDiv.style.paddingTop = `${((lastElemBottomPage * scaledUpPageHeightPixels) - headerDivTop)}px`;
    }
  }

  const pushElementWithTableToNextPage = (element: HTMLElement, table: HTMLElement, yAdjust : number, scaledUpPageHeightPixels: number) => {
    const elementTop = scaleForWindowDevicePixelRatio(element.getBoundingClientRect().top + window.scrollY + yAdjust);

    const elementTopPage = Math.floor(elementTop / scaledUpPageHeightPixels);
    
    const tbody = table.getElementsByTagName('tbody').item(0) as HTMLTableSectionElement;

    const rows = tbody.getElementsByTagName('tr');

    if (rows.length > 0) {
      const row = rows.item(0) as HTMLTableRowElement;

      const lastRow = rows.item(rows.length - 1) as HTMLTableRowElement;

      const lastRowBottom = scaleForWindowDevicePixelRatio(lastRow.getBoundingClientRect().bottom + window.scrollY + yAdjust);
      const tableBottom = scaleForWindowDevicePixelRatio(table.getBoundingClientRect().bottom + window.scrollY + yAdjust);


      let responsiveDiv = table.parentElement;
      if (responsiveDiv && (responsiveDiv.tagName.toUpperCase() != "DIV" || responsiveDiv.className != "table-responsive")) {
        responsiveDiv = null;
      }

      let responsiveDivBottom = null;
      if (responsiveDiv) {
        responsiveDivBottom = scaleForWindowDevicePixelRatio(responsiveDiv.getBoundingClientRect().bottom + window.scrollY + yAdjust);
      }

      const rowDelta = (responsiveDivBottom ?? tableBottom) - lastRowBottom;

      const rowBottom = scaleForWindowDevicePixelRatio(row.getBoundingClientRect().bottom + window.scrollY + yAdjust);

      
      const firstRowBottomPage = Math.floor((rowBottom + rowDelta)/ scaledUpPageHeightPixels);

      if (elementTopPage < firstRowBottomPage) {
        element.style.paddingTop = `${((firstRowBottomPage * scaledUpPageHeightPixels) - elementTop)}px`;
        return true;
      }
    }
    return false;
  }


  const scaleForWindowDevicePixelRatio = (value: number) => value * window.devicePixelRatio;

  const processBOMTable = (table : HTMLElement, yAdjust : number, scaledUpPageHeightPixels : number) =>
  {
    
    const tableTop = scaleForWindowDevicePixelRatio(table.getBoundingClientRect().top + window.scrollY + yAdjust);

    const tableStartPage = Math.ceil(tableTop / scaledUpPageHeightPixels);

    splitBOMTableAcrossPageBreaks(scaledUpPageHeightPixels, tableStartPage, yAdjust, table);
  }

  const splitBOMTableAcrossPageBreaks: (scaledUpPageHeightPixels: number, pageNumber: number, yAdjust: number, table: HTMLElement, thead?: Node | null, tfoot?: Node | null) => void
    = (scaledUpPageHeightPixels, pageNumber, yAdjust, table, thead = undefined, tfoot = undefined) => {

      const pageBreakPixels = scaledUpPageHeightPixels * pageNumber;

      const tableTop = scaleForWindowDevicePixelRatio(table.getBoundingClientRect().top + window.scrollY + yAdjust);

      if (tableTop > pageBreakPixels) {
        //table on next page, so call again with updated page height for next page
        splitBOMTableAcrossPageBreaks(scaledUpPageHeightPixels, pageNumber + 1, yAdjust, table, thead, tfoot);
      } else {

        const tbody = table.getElementsByTagName('tbody').item(0) as HTMLTableSectionElement;

        const rows = tbody.getElementsByTagName('tr');

        if (rows.length > 0) {

          const row = rows.item(0) as HTMLTableRowElement;

          const lastRow = rows.item(rows.length - 1) as HTMLTableRowElement;

          const lastRowBottom = scaleForWindowDevicePixelRatio(lastRow.getBoundingClientRect().bottom + window.scrollY + yAdjust);
          const tableBottom = scaleForWindowDevicePixelRatio(table.getBoundingClientRect().bottom + window.scrollY + yAdjust);


          let responsiveDiv = table.parentElement;
          if (responsiveDiv && (responsiveDiv.tagName.toUpperCase() != "DIV" || responsiveDiv.className != "table-responsive")) {
            responsiveDiv = null;
          }

          let responsiveDivBottom = null;
          if (responsiveDiv) {
            responsiveDivBottom = scaleForWindowDevicePixelRatio(responsiveDiv.getBoundingClientRect().bottom + window.scrollY + yAdjust);
          }

          const rowDelta = (responsiveDivBottom ?? tableBottom) - lastRowBottom;

          const rowBottom = scaleForWindowDevicePixelRatio(row.getBoundingClientRect().bottom + window.scrollY + yAdjust);


          if (rowBottom + rowDelta >= pageBreakPixels) {
            //Either the header / first row of a table is crossing a page break, add a margin top to push it down and call again with updated page height for next page.   
            table.style.marginTop = `${(pageBreakPixels - tableTop)}px`;
            splitBOMTableAcrossPageBreaks(scaledUpPageHeightPixels, pageNumber + 1, yAdjust, table, thead, tfoot);
          } else {
            const rowsToSplit: Node[] = [];

            //Iterate rows until we find one that exceeds the current page.
            for (let i: number = 0; i < rows.length; i++) {


              let row = rows.item(i) as HTMLTableRowElement;
              let rowBottom = scaleForWindowDevicePixelRatio(row.getBoundingClientRect().bottom + window.scrollY + yAdjust);

              if (rowBottom + rowDelta >= pageBreakPixels) {
                //rows now exceed the current page, add to array that will be added to new table
                rowsToSplit.push(row);
              }
            }

            //if rowsToSplit, create a new table and add them to it.
            if (rowsToSplit.length > 0) {
              //clone the thead from the current table, or the arg if we have previously identified it.
              if (thead === undefined) {
                thead = table.getElementsByTagName('thead').item(0)?.cloneNode(true);
              } else if (thead !== null) {
                thead = thead.cloneNode(true);
              }
              //clone the thead from the current table, or the arg if we have previously identified it.
              if (tfoot === undefined) {
                tfoot = table.getElementsByTagName('tfoot').item(0)?.cloneNode(true);
              } else if (tfoot !== null) {
                tfoot = tfoot.cloneNode(true);
              }

              const newTable = document.createElement('table');
              newTable.className = table.className;

              if (thead != null) {
                newTable.appendChild(thead as Node);
              }

              const newTBody = document.createElement('tbody');
              newTable.appendChild(newTBody);

              newTBody.append(...rowsToSplit);

              if (tfoot != null) {
                newTable.appendChild(tfoot as Node);
              }

              let predecessor: HTMLElement = table;

              let newContent: HTMLElement = newTable;
              if (responsiveDiv) {
                predecessor = responsiveDiv;
                newContent = document.createElement('div');
                newContent.className = "table-responsive";
                newContent.appendChild(newTable);
              }


              let predecessorBottom = scaleForWindowDevicePixelRatio(predecessor.getBoundingClientRect().bottom + window.scrollY + yAdjust);

              //push the new table down so that it is on the next page.
              newContent.style.marginTop = `${(pageBreakPixels - predecessorBottom)}px`;

              predecessor.after(newContent);

              //call again for the new table with updated page height for the next page.
              splitBOMTableAcrossPageBreaks(scaledUpPageHeightPixels, pageNumber + 1, yAdjust, newTable, thead, tfoot);
            }
          }
        }
      }

    }

  const rejoinBOMTable: (tableClassName: string) => void
    = (tableClassName) => {
      let bomTables = document.getElementsByClassName(tableClassName);

      if (bomTables.length > 0) {
        const mainTable = bomTables.item(0) as HTMLElement;

        mainTable.style.marginTop = '';

        const mainTBody = mainTable?.querySelector('tbody');

        for (let i: number = 1; i < bomTables.length; i++) {
          const table = bomTables.item(i);

          const rows = Array.from(table?.querySelectorAll('tbody tr') as NodeListOf<Element>);

          mainTBody?.append(...rows);
        }

        bomTables = document.getElementsByClassName(tableClassName);

        for (let j: number = (bomTables.length - 1); j > 0; j--) {
          let responsiveDiv = bomTables.item(j)?.parentElement;
          if (responsiveDiv && (responsiveDiv.tagName.toUpperCase() != "DIV" || responsiveDiv.className != "table-responsive")) {
            responsiveDiv = null;
          }
          (responsiveDiv ?? bomTables.item(j))?.remove();
        }
      }
    }

  const LoadingButtonContents = () =>
    <>
      <FontAwesomeIcon icon={faSpinner} spin aria-hidden />&nbsp;Applying...
    </>


  return (
    <Container>
      {state.screenshot === "" && (
        <>
          <Form onSubmit={handleSubmit.bind(this)} id="StudyForm">
            <fieldset disabled={submitting}>
              <Row className="my-4">
                <Col xs={12} md={10} className="border-md-right ps-0 ms-0">
                  <Row>
                    {/* <Col lg={2}>
                      <Row noGutters className="mb-1">
                        <Col>
                          <Form.Group className="mb-0">
                            <Form.Text>Quotation Ref</Form.Text>
                            <Form.Control
                              type="number"
                              id="quotationRef"
                              name="quotationRef"
                              size="sm"
                              value={inputdata?.InterviewData?.userId ?? ""}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col> */}
                    <Col lg={9} className="border-lg-right ">
                      <Tabs
                        defaultActiveKey="profile"
                        id="controlled-tab-example"
                        activeKey={inputs.meterType}
                        onSelect={(k) => changeMeterTab(k)}
                        className="mb-3"
                      >
                        <Tab
                          eventKey="wc-meter-connections"
                          title="WC Meter Connections"
                          disabled={props.HasInterview}
                        >
                          <Row>
                            <Col xs={6}>
                              <Form.Group>
                                <Form.Text>Single Phase</Form.Text>
                                <Form.Control
                                  type="number"
                                  id="singlePhaseConnections"
                                  name="singlePhaseConnections"
                                  size="sm"
                                  value={inputs.singlePhaseConnections}
                                  onChange={handleInputChange}
                                  onKeyDown={(e: { keyCode: number; preventDefault: () => any; }) => (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 110) && e.preventDefault()}
                                  onPaste={(e: { preventDefault: () => void; })=>{ e.preventDefault(); }}
                                />
                              </Form.Group>
                            </Col>
                            <Col xs={6}>
                              <Form.Group>
                                <Form.Text>Three Phase</Form.Text>
                                <Form.Control
                                  type="number"
                                  id="threePhaseConnections"
                                  name="threePhaseConnections"
                                  size="sm"
                                  value={inputs.threePhaseConnections}
                                  onChange={handleInputChange}
                                  onKeyDown={(e: { keyCode: number; preventDefault: () => any; }) => (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 110) && e.preventDefault()}
                                  onPaste={(e: { preventDefault: () => void; })=>{ e.preventDefault(); }}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Tab>
                        <Tab
                          eventKey="ct-meter-connections"
                          title="CT Meter Connections"
                          disabled={props.HasInterview}
                        >
                          <Row>
                            <Col xs={6}>
                              <Form.Group>
                                <Form.Text>Connections</Form.Text>
                                <Form.Control
                                  type="number"
                                  id="connections"
                                  name="connections"
                                  size="sm"
                                  value={inputs.connections}
                                  onChange={handleInputChange}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Tab>
                        <Tab
                          eventKey="ms-meter-connections"
                          title="MS Meter Connections"
                          disabled={props.HasInterview}
                        >
                          <Row>
                            <Col xs={6}>
                              <Form.Text>Apartments</Form.Text>
                              <Form.Group>
                                <Form.Control
                                  type="number"
                                  id="multiSwitchConnections"
                                  name="multiSwitchConnections"
                                  min="0"
                                  placeholder="apartments"
                                  value={(inputs.multiSwitchConnections.length > 0) ? inputs.multiSwitchConnections[0] : 0}
                                  onChange={handleMultiSwitchChange}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Tab>
                      </Tabs>
                    </Col>
                    <Col lg={3}>
                      <Row noGutters className="mb-1">
                        <Col>
                          <Form.Group>
                            <Form.Text>Total kVA</Form.Text>
                            <Form.Control
                              type="number"
                              id="totalkVA"
                              name="totalkVA"
                              size="sm"
                              value={inputs.totalkVA}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {/* <Row noGutters className="mb-1">
                        <Col>
                          <Form.Group>
                            <Button
                              variant="secondary"
                              size="sm"
                              block
                              onClick={() =>
                                handleInputToggle("isSiteExcavationCostIncluded")
                              }
                            >
                              <span className="m-2">
                                Include Site Excavation Cost
                              </span>
                              <FontAwesomeIcon
                                icon={
                                  inputs.isSiteExcavationCostIncluded
                                    ? faCheck
                                    : faTimes
                                }
                              />
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row> */}
                    </Col>
                  </Row>

                </Col>
                <Col xs={12} md={2} >
                  <Row>
                    <Col>
                      <Form.Group className="ml-1">
                        <Form.Text>Postcode</Form.Text>
                        {/* <Dropdown>
                          <Dropdown.Toggle as="small" id="dropdown-custom-1">
                            {inputs.searchType}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="super-colors">
                            <Dropdown.Item
                              className="btn-sm"
                              onClick={() => handleSearchType("Postcode")}
                            >
                              Postcode
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="btn-sm"
                              onClick={() => handleSearchType("Grid Ref")}
                            >
                              Grid ref
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="btn-sm"
                              onClick={() => handleSearchType("LatLng")}
                            >
                              LatLng
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> */}
                        <Form.Control
                          type="text"
                          id="searchValue"
                          name="searchValue"
                          size="sm"
                          value={inputs.searchValue}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6} md={12} className="pr-1 pr-md-0">
                      <Button
                        block
                        type="submit"
                        size="sm"
                        className="mb-1"
                        variant="outline-primary"
                        disabled={submitting}
                      >
                        {submitting ? <LoadingButtonContents /> : "Apply"}
                      </Button>
                      <RestartButton
                        RestartCallback={resetAndTriggerCallback}
                        ButtonVariant={"outline-secondary"}
                        ButtonSize="sm"
                        ButtonDisabled={submitting}
                        ButtonDisplayBlock={true}
                        ConfirmWithModal={true}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className="mb-4 border" style={{ height: "500px" }}>
                  <iframe
                    title="connectlite"
                    id="connectlite"
                    src={maphost}
                    frameBorder="0"
                    style={{
                      zIndex: 1,
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      top: "0px",
                      left: "0px",
                      border: "none",
                    }}
                    allowFullScreen
                    allow="clipboard-write"
                  ></iframe>
                </Col>
              </Row>
            </fieldset>
          </Form>
        </>
      )}

      {state.screenshot !== "" && (
        <>         
            <div id="content">
                <div id="budget-header" style={{ display: "none" }}>
                  <Row className="mb-4">
                    <img src={budgetHeader} />
                  </Row>
                </div>
                <div id="sub-content">
                  <Row className="my-4">
                    <h2 className="nien_header fix_pdf_letter_spacing">NEW CONNECTION – BUDGETARY ESTIMATE</h2>
                  </Row>
                  <Row>
                    <Table size="sm" responsive>
                      <tbody>
                        <tr>
                          <td><h4 className="text-left w-20">Ref: {state.quoteRef}</h4></td>
                          <td><h4 className="text-left">Date: {state.quoteDateTime}</h4></td>
                        </tr>
                      </tbody>
                    </Table>
                  </Row>
                  <Row className="mt-4 mb-2">
                    <div className="nien_subheader fix_pdf_letter_spacing">Your Budget Estimate</div>
                  </Row>
                  <Row className="mt-2 mb-4">
                    <Col sm={6} lg={4}>
                    <div className="nien_subheader nien_subheader_grey fix_pdf_letter_spacing">Total Cost</div>
                    <div className="nien_estimate">£{parseFloat(state.totalCost).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-4">
                    <Col sm={6} lg={4}>
                    <div className="nien_subheader nien_subheader_grey fix_pdf_letter_spacing">Non-Contestable</div>
                    <div className="nien_estimate">£{parseFloat(state.nonContestableTotalCost).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                    </Col>
                    <Col sm={6} lg={4}>
                    <div className="nien_subheader nien_subheader_grey fix_pdf_letter_spacing">Contestable</div>
                    <div className="nien_estimate">£{parseFloat(state.contestableTotalCost).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                    </Col>
                  </Row>
                  <Row className="my-4">
                    <div className="nien_subheader fix_pdf_letter_spacing">Your Connection Requirements</div>
                    <Table size="sm" responsive>
                      <tbody>
                        <tr>
                          <td><h5 className="text-left w-20">Postcode:</h5></td>
                          <td><h5 className="text-left">{state.postcode}</h5></td>
                        </tr>
                        {props.InterviewData?.propertyType === 1 && props.InterviewData?.domesticDwellings != null && (
                          props.InterviewData?.domesticDwellings.map((answer, i) => {
                            return (
                              <tr key={i}>
                                <td><h5 className="text-left w-20 nowrap">Connection{props.InterviewData?.domesticDwellings.length !== undefined ? props.InterviewData?.domesticDwellings.length > 1 ? (" " + (i + 1)) : "" : ""}:</h5></td>
                                <td>
                                  <h5 className="text-left">Domestic Connection - {answer.propertyType.replace(/([A-Z])/g, (key) => ` ${key}`)}</h5>
                                  {answer.evCharger && answer.evCharger > 0 ? <h5>{answer.evCharger} kVA EV Charger</h5> : ""}
                                  {answer.heatPump && answer.heatPump > 0 ? <h5>{answer.heatPump} kVA Heat Pump</h5> : ""}
                                </td>
                              </tr>
                            )
                          })
                        )}
                        {props.InterviewData?.propertyType === 1 && (
                          props.InterviewData?.apartments.map((answer, i) => {
                            return (
                              <tr key={i}>
                                <td><h5 className="text-left w-20 nowrap">Connection{props.InterviewData?.apartments.length !== undefined ? props.InterviewData?.apartments.length > 1 ? (" " + (i + 1)) : "" : ""}:</h5></td>
                                <td>
                                  <h5 className="text-left">Domestic Connection - Apartment</h5>
                                  {answer.evCharger && answer.evCharger > 0 ? <h5>{answer.evCharger} kVA EV Charger</h5> : ""}
                                  {answer.heatPump && answer.heatPump > 0 ? <h5>{answer.heatPump} kVA Heat Pump</h5> : ""}
                                </td>
                              </tr>
                            )
                          })
                        )}
                        {props.InterviewData?.propertyType === 2 && (
                          <tr>
                            <td><h5 className="text-left w-20">Connection:</h5></td>
                            <td>
                              <h5 className="text-left">Commercial Connection - {props.InterviewData?.phase === 1 ? "Single Phase" : props.InterviewData?.phase === 3 ? "Three Phase" : ""}</h5>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td><h5 className="text-left w-20">Total kVA:</h5></td>
                          <td><h5 className="text-left">{inputs.totalkVA} kVA</h5></td>
                        </tr>
                      </tbody>
                    </Table>
                  </Row>
                    <Row className="my-4">
                      <div className="nien_subheader fix_pdf_letter_spacing" id="networkMapHeaderDiv">Network Map</div>
                    </Row>
                    <Row className="my-4">
                      <>
                        <img src={state.screenshot} id="networkMapImage" />
                      </>
                    </Row>
                  {state.nonContestablePriceBookItems && state.nonContestablePriceBookItems.length > 0  && (
                    <>
                      <Row className="my-4">
                        <div className="nien_subheader fix_pdf_letter_spacing" id="nonContestableHeaderDiv">Non-Contestable</div>
                      </Row>        
                      <Row>
                        <Table size="sm" className="nien_billofmaterials_table nien_noncontestable_billofmaterials_table" id="nien_noncontestable_billofmaterials_table" responsive>
                          <thead>
                            <tr>
                              {
                                Object.keys(state.nonContestablePriceBookItems[0])
                                  .filter(key => key in priceBookItemColumnDefinitions && priceBookItemColumnDefinitions[key].show && priceBookItemColumnDefinitions[key].displayName !== '')
                                  .map((key, index) => (
                                    <th className={priceBookItemColumnDefinitions[key].headerClassName}
                                      key={`${key}_${index}`}
                                      style={{ textTransform: "capitalize" }}
                                      colSpan={priceBookItemColumnDefinitions[key].headerColspan}
                                    >
                                      {(priceBookItemColumnDefinitions[key].displayName ?? key).replace(/([A-Z])/g, (key) => ` ${key.toLowerCase()}`)}
                                    </th>
                                  ))
                              }
                            </tr>
                          </thead>
                          <tbody>
                            {state.nonContestablePriceBookItems.map((item, index) => (
                              <tr key={`${item.itemCode}_${index}`}>
                                {
                                  Object.keys(item)
                                    .filter(key => key in priceBookItemColumnDefinitions && priceBookItemColumnDefinitions[key].show)
                                    .map((key, index) => (
                                      <td className={priceBookItemColumnDefinitions[key].className ?? ""} key={`${item[key]}_${index}`}>
                                        {priceBookItemColumnDefinitions[key].formatFunction?.(item[key]) ?? item[key] as string}
                                        {(key == 'itemCode' && item.hasAssumedGroundTypes) ? " *" : ""}
                                      </td>
                                    ))
                                }
                              </tr>
                            ))}
                          </tbody>
                          {
                            (state.nonContestablePriceBookItems.find(item => item.hasAssumedGroundTypes) != null) && (
                              <tfoot>
                                <tr>
                                  <td colSpan={Object.keys(state.nonContestablePriceBookItems[0]).filter(key => key in priceBookItemColumnDefinitions && priceBookItemColumnDefinitions[key].show).length}>
                                    * An assumed ground type was used for all or part of this line item.
                                  </td>
                                </tr>
                              </tfoot>
                            )
                          }
                        </Table>
                      </Row>
                    </>
                  )} 
                  <Row className="mt-2 mb-2">
                    <div className="nien_subheader nien_subheader_grey fix_pdf_letter_spacing" id="activityBasedPricingHeaderDiv">ABP</div>
                  </Row>
                  <Row className="mt-2 mb-4">
                    <Col sm={6} lg={4}>
                    <div className="nien_estimate" id="activityBasedPricingValueDiv">£{parseFloat(state.activityBasedPricing).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                    </Col>
                  </Row>
                  {state.contestablePriceBookItems && state.contestablePriceBookItems.length > 0 && (
                    <>
                      <Row className="my-4">
                        <div className="nien_subheader fix_pdf_letter_spacing" id="contestableHeaderDiv">Contestable</div>
                      </Row>        
                      <Row>
                        <Table size="sm" className="nien_billofmaterials_table nien_contestable_billofmaterials_table" id="nien_contestable_billofmaterials_table" responsive>
                          <thead>
                            <tr>
                              {
                                Object.keys(state.contestablePriceBookItems[0])
                                  .filter(key => key in priceBookItemColumnDefinitions && priceBookItemColumnDefinitions[key].show && priceBookItemColumnDefinitions[key].displayName !== '')
                                  .map((key, index) => (
                                    <th className={priceBookItemColumnDefinitions[key].headerClassName}
                                      key={`${key}_${index}`}
                                      style={{ textTransform: "capitalize" }}
                                      colSpan={priceBookItemColumnDefinitions[key].headerColspan}
                                    >
                                      {(priceBookItemColumnDefinitions[key].displayName ?? key).replace(/([A-Z])/g, (key) => ` ${key.toLowerCase()}`)}
                                    </th>
                                  ))
                              }
                            </tr>
                          </thead>
                          <tbody>
                            {state.contestablePriceBookItems.map((item, index) => (
                              <tr key={`${item.itemCode}_${index}`}>
                                {
                                  Object.keys(item)
                                    .filter(key => key in priceBookItemColumnDefinitions && priceBookItemColumnDefinitions[key].show)
                                    .map((key, index) => (
                                      <td className={priceBookItemColumnDefinitions[key].className ?? ""} key={`${item[key]}_${index}`}>
                                        {priceBookItemColumnDefinitions[key].formatFunction?.(item[key]) ?? item[key] as string}
                                        {(key == 'itemCode' && item.hasAssumedGroundTypes) ? " *" : ""}
                                      </td>
                                    ))
                                }
                              </tr>
                            ))}
                          </tbody>
                          {
                            (state.contestablePriceBookItems.find(item => item.hasAssumedGroundTypes) != null) && (
                              <tfoot>
                                <tr>
                                  <td colSpan={Object.keys(state.contestablePriceBookItems[0]).filter(key => key in priceBookItemColumnDefinitions && priceBookItemColumnDefinitions[key].show).length}>
                                    * An assumed ground type was used for all or part of this line item.
                                  </td>
                                </tr>
                              </tfoot>
                            )
                          }
                        </Table>
                      </Row>
                    </>
                  )}
                  <Row className="my-4">
                    <div className="nien_maptext" id="nien_maptext">The above costs are based on a high level desktop study only, actual costs of the connection works may differ significantly following more detailed design assessment, environmental impact studies, taking account of any wayleaves, consents, and the construction sequence of the works etc. This budget estimate does not constitute an offer of terms for NIE Networks to carry out these works.
                      I hope that this is sufficient at this stage to allow you to develop your feasibility analysis, but please contact our connections team if you would like to progress with a formal offer.
                    </div>
                  </Row>
                  {(state.buildingOverride === true) && (
                  <Row className="my-4">
                    <div className="nien_maptext" id="nien_maptext">Please note, drawing the site over existing buildings has been allowed in this quote.</div>
                  </Row>
                  )
                  }
                </div>         
            </div>
          <Row>
            <Button
              id="btnSavePdf"
              color="secondary"
              size="sm"
              className="pdf_button"
              onClick={() => exportPDF()}
              disabled={loadingPDF}
            >
              {loadingPDF ? <FontAwesomeIcon icon={faSpinner} spin /> : "Save PDF"}
            </Button>
            <RestartButton
              RestartCallback={resetAndTriggerCallback}
              ButtonVariant={"outline-secondary"}
              ButtonSize="sm"
              ButtonClassName={"pdf_button"}
              ButtonDisabled={loadingPDF}
              ConfirmWithModal={false}
            />
          </Row>
          <Row>
            <p></p>
          </Row>
          <Row>
            <p></p>
          </Row>
        </>
      )}
    </Container>
  );
};

export default StudyForm;