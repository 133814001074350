import { IPostData, MeterTypes, Phases, createPostData } from "eatl.connectlite.nien.web.userinterview.component";
import axios, { AxiosResponse } from "axios";
import { Inputs } from "../models/models";
import { InterviewSource } from "eatl.connectlite.nien.web.userinterview.component";

const { REACT_APP_USERINTERVIEW_API: apiUrl } = process.env;

const saveinterviewurl = apiUrl + "/UserInterview/SaveUserInterview" || "";
const savestudyurl = apiUrl + "/UserInterview/SaveStudy" || "";
const paramsUrl = apiUrl + "/UserInterview/GetInterviewParams" || "";

const { post, get } = axios;

export const userInterviewService = {

    saveInterviewData: async (interviewdata: IPostData | null, inputs: Inputs | null) => {
        if (interviewdata) {
            return post(`${saveinterviewurl}`, interviewdata);
        } else {
            if (inputs) {
                const idata = createPostData();
                idata.source = InterviewSource.Internal;
                idata.totalKVA = inputs.totalkVA;
                idata.postcode = inputs.searchValue;
                idata.interviewId = inputs.quotationRef === "" ? undefined : inputs.quotationRef;
                idata.singlePhaseConnections = inputs.singlePhaseConnections;
                idata.threePhaseConnections = inputs.threePhaseConnections;

                return post(`${saveinterviewurl}`, idata);
            } else {
                return null;
            }
        }
    },

    saveStudy: async (inputs: Inputs) => {
        if (inputs) {
            const sdata = createPostData();
            sdata.source = InterviewSource.Internal;
            sdata.totalKVA = inputs.totalkVA;
            sdata.postcode = inputs.searchValue;
            sdata.interviewId = inputs.quotationRef === "" ? undefined : inputs.quotationRef;
            sdata.meterType = userInterviewService.getMeterType(inputs);

            if (sdata.meterType === MeterTypes.MS && inputs.connections > 0) {
                sdata.threePhaseConnections = (inputs.landlordMeterPhase ?? Phases.Single) === Phases.Three ? 1 : 0;
                sdata.singlePhaseConnections = inputs.connections + ((inputs.landlordMeterPhase ?? Phases.Single) === Phases.Single ? 1 : 0);
            } else if (sdata.meterType === MeterTypes.CT && inputs.connections > 0) {
                sdata.threePhaseConnections = inputs.connections;
                sdata.singlePhaseConnections = 0;
            } else {
                sdata.singlePhaseConnections = inputs.singlePhaseConnections;
                sdata.threePhaseConnections = inputs.threePhaseConnections;
            }

            return post(`${savestudyurl}`, sdata);
        } else {
            return null;
        }
    },

    getInterviewParams: async () => {
        return get(`${paramsUrl}`);
    },

    getMeterType: (inputs: Inputs) : MeterTypes.CT | MeterTypes.MS | MeterTypes.WC => {
        
        switch(inputs.meterType)
        {
            case "wc-meter-connections": return MeterTypes.WC;
            case "ct-meter-connections": return MeterTypes.CT;
            case "ms-meter-connections": return MeterTypes.MS;
        }
    }
}