import React, { useState } from "react"
import { Button, ButtonProps, Modal } from "react-bootstrap"

interface IRestartButtonProps {
    RestartCallback: () => void,
    ButtonVariant?: ButtonProps["variant"],
    ButtonDisabled?: boolean,
    ButtonText?: string,
    ButtonDisplayBlock?: boolean,
    ButtonSize?: ButtonProps["size"],
    ButtonClassName?: string,
    ConfirmWithModal?: boolean,
    ModalCancelButtonText?: string,
    ModalMessageText?: string,
}

const RestartButton = ({
    RestartCallback,
    ButtonVariant = "secondary",
    ButtonDisabled = false,
    ButtonText = "Restart",
    ButtonDisplayBlock = false,
    ButtonSize = undefined,
    ButtonClassName = undefined,
    ConfirmWithModal = false,
    ModalCancelButtonText = "Cancel",
    ModalMessageText = `Are you sure you want to ${ButtonText.toLowerCase()}? All progress will be lost!`,
}: IRestartButtonProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const callResartCallback = () => {
        if (ConfirmWithModal) {
            setShowModal(false);
        }
        RestartCallback();
    }

    return <React.Fragment>
        <Button
            block={ButtonDisplayBlock}
            size={ButtonSize}
            type="button"
            variant={ButtonVariant}
            onClick={() => (ConfirmWithModal) ? setShowModal(true) : RestartCallback()}
            disabled={ButtonDisabled}
            className={ButtonClassName}
          >
          {ButtonText}
        </Button>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Body>{ModalMessageText}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              {ModalCancelButtonText}
            </Button>
            <Button variant="danger" onClick={callResartCallback}>
            {ButtonText}
            </Button>
          </Modal.Footer>
        </Modal>
    </React.Fragment>
}

export default RestartButton;