import { IPostData, InterviewSource, createPostData } from "eatl.connectlite.nien.web.userinterview.component";
import { useState } from "react";
import { InterviewParam, StageType } from "../models/models";
import { userInterviewService } from "../services/userInterviewService";
import StudyForm from "./ConnectionPoints/StudyForm";
import DefaultSelect from "./DefaultSelect";
import ToastHandler, { IToast, apiErrorMessagesToToast, fatalApiErrorToast } from "./ErrorHandling/ToastHandler";
import InterviewWrapper from "./Interview/InterviewWrapper";
import LoadingPleaseWait from "./LoadingPleaseWait";
import useLocalStorage from "../hooks/useLocalStorageWrapper";
import Heatmap from "./Heatmap/Heatmap";

interface IDisplayStageProps {
    interviewParams: InterviewParam[],
    setErrorMessageCallback: (errorMessage: string) => void,
}

const { REACT_APP_ENABLE_HEATMAP } = process.env;
const enableHeatmap = REACT_APP_ENABLE_HEATMAP === "true";

const STAGE_DEFAULT: StageType = null;

interface IDisplayStageLocalStorageState {
    stage: StageType,
    interviewData: IPostData,
}

const DisplayStage = ({interviewParams, setErrorMessageCallback} : IDisplayStageProps) => {   

    const [localStorageState, setLocalStorageState] = useLocalStorage<IDisplayStageLocalStorageState>("ConnectLite.Internal.DisplayStage.state", {
        stage: STAGE_DEFAULT,
        interviewData: {...createPostData(), source: InterviewSource.Internal },
    });

    const [toast, setToast] = useState<IToast[]>([]);
    const [submitting, setSubmitting] = useState(false);
    const [hasInterview, setHasInterview] = useState(false);

    const removeToast = (indexToRemove: number) => {
        setToast(toast.filter((error, index) => index !== indexToRemove));
    };
    
    const replaceToast = (toast: IToast[]) => {
        setToast(toast);
    }

    const appendToast = (toastToAppend: IToast[]) => {
        setToast([...toast, ...toastToAppend]);
    }

    const reset = () => {
        setLocalStorageState({
            ...localStorageState,
            interviewData: {...createPostData(), source: InterviewSource.Internal },
            stage: STAGE_DEFAULT,
        });
        setToast([]);
        setHasInterview(false);
        setSubmitting(false);
    }

    const interviewFinish = async (interviewdata: IPostData) => {
        //console.log(interviewdata);
        if (interviewdata) {
            await saveInterviewData(true, interviewdata)
            .then(response => {
                if (response) {
                    setToast([{
                        "aria-atomic": true,
                        "aria-live": "polite",
                        role: "alert",
                        "toast-className": 'bg-success',
                        'header-className': 'text-dark',
                        'body-className': 'text-white',
                        title: 'User Interview Captured',
                        message: 'Please use this screen to alter any settings. To move to the next stage please click apply.',
                      } as IToast]);

                      setHasInterview(true);
                    
                    setLocalStorageState({
                        ...localStorageState,
                        interviewData: response,
                        stage: "study",
                    });
                }
            })
            .catch(err => {
                console.log(err);                
                setErrorMessageCallback(err);
            });
        }
    }

    const saveInterviewData : (bypasswait: boolean, interviewdata: IPostData) => Promise<IPostData | null>
        = (bypasswait, interviewdata) => {
        return new Promise((resolve, reject) => {

            if (interviewdata && interviewdata !== null) {
                setSubmitting(true);
                if (!bypasswait) {
                    setLocalStorageState({
                        ...localStorageState,
                        stage: "submission",
                    });
                }

                interviewdata.source = InterviewSource.Internal;
                userInterviewService.saveInterviewData(interviewdata, null)
                    .then((response => {
                        
                        if (response) {
                            switch (response.status)
                            {
                                case 200:
                                    interviewdata.interviewId = response.data.quoteRef;
                                    resolve(interviewdata);
                                    break;
                                case 400:
                                    setErrorMessageCallback(response.statusText);
                                    break;
                                default:
                                    setErrorMessageCallback("Unable to process your data, please try again",);
                                    break;
                            }
                        }
                    }))
                    .catch(err => {
                        if (err.response && err.response.status === 400 && err.response.data?.Messages)
                        {
                            replaceToast(apiErrorMessagesToToast(err.response.data.Messages as string[]));
                        }
                        else if (err.message)
                        {
                            replaceToast([fatalApiErrorToast] as IToast[]);
                        }
                    })
                    .finally(() => {    
                        setSubmitting(false);
                    });
            } else {
                resolve(null);
            }
        });
    }

          


    return (
    <>
        {
            ((localStorageState.stage === null) && <DefaultSelect
                                                        interviewCallback={() => {
                                                            setLocalStorageState({
                                                                ...localStorageState,
                                                                stage: "interview",
                                                            });
                                                        }}
                                                        skipToStudyCallback={() => {
                                                            setLocalStorageState({
                                                                ...localStorageState,
                                                                stage: "study",
                                                            });
                                                        }}
                                                        heatmapCallback={() => {
                                                            setLocalStorageState({
                                                                ...localStorageState,
                                                                stage: "heatmap",
                                                            });
                                                        }} />)
            || ((localStorageState.stage === "interview") && (
                <>
                    <ToastHandler
                        toast={toast}
                        removeToastCallback={removeToast}
                    />
                    <InterviewWrapper
                        SubmissionCallback={interviewFinish}
                        submitting={submitting}
                        interviewParams={interviewParams as InterviewParam[]}
                        ResetCallback={reset}
                    />
                </>
            ))
            || ((localStorageState.stage === "study") && (
                <>
                    <ToastHandler
                        toast={toast}
                        removeToastCallback={removeToast}
                    />
                    <StudyForm
                        InterviewData={localStorageState.interviewData}
                        SaveInterviewData={ saveInterviewData.bind(undefined, true) }
                        InterviewParams={interviewParams}
                        ReplaceToastCallback={replaceToast}
                        AppendToastCallback={appendToast}
                        HasInterview={hasInterview}
                        ResetCallback={reset}
                    />
                </>
            ))
            || (enableHeatmap && (localStorageState.stage === "heatmap") && (
                <Heatmap 
                    ResetCallback={reset}
                />
            ))
            || ((localStorageState.stage === "submission") && <LoadingPleaseWait />)
            
        }
    </>

    );
};

export default DisplayStage;