interface IPriceBookItemColumnProperties
{
    show: boolean,
    headerClassName?: string,    
    displayName?: string,
    className?: string,
    formatFunction?: (value: string) => string,
    headerColspan: number,
}

function parseFloatAsFormattedString(value: string) : string {
    return parseFloat(value as string).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function fixFlexible(value: string) : string {
    return value.replace(/\((\S+\/\S+)\)/g, (match, p1) => {
        return `(${p1.replace(/\//g, ' / ')})`;
      });
}


const defaults : IPriceBookItemColumnProperties =
{
    show: false,
    headerClassName: "fix_pdf_letter_spacing",
    headerColspan: 1
}

const priceBookItemColumnDefinitions: { [id: string]: IPriceBookItemColumnProperties; } =
{
    "pricebookTab": {...defaults},
    "parentCategory": {...defaults},
    "childCategory": {...defaults},
    "swe": {...defaults, displayName: "Id"},
    "sweDescription": {...defaults, displayName: "Description"},
    "sweDescriptionDetail": {...defaults, displayName: "DescriptionDetail"},
    "materialOrLabour": {...defaults},
    "materialDescription": {...defaults},
    "id": {...defaults},
    "itemId": {...defaults},
    "itemCode": {...defaults, show: true},
    "itemName": {...defaults, show: true},
    "category1Code": {...defaults, show: true, displayName: "Category 1", headerColspan: 2},
    "category1Name": {...defaults, show: true, displayName: "", formatFunction: fixFlexible},
    "category2Code": {...defaults, show: true, displayName: "Category 2", headerColspan: 2},
    "category2Name": {...defaults, show: true, displayName: "", formatFunction: fixFlexible},
    "category3Code": {...defaults, show: true, displayName: "Category 3", headerColspan: 2},
    "category3Name": {...defaults, show: true, displayName: "", formatFunction: fixFlexible},
    "category4Code": {...defaults, show: true, displayName: "Category 4", headerColspan: 2},
    "category4Name": {...defaults, show: true, displayName: "", formatFunction: fixFlexible},
    "labourTime": {...defaults, show: true, headerClassName: "align_right fix_pdf_letter_spacing", className: "align_right", formatFunction: parseFloatAsFormattedString},
    "labourCost": {...defaults, show: true, headerClassName: "align_right fix_pdf_letter_spacing", className: "align_right", formatFunction: parseFloatAsFormattedString},
    "materialCost": {...defaults, show: true, headerClassName: "align_right fix_pdf_letter_spacing", className: "align_right", formatFunction: parseFloatAsFormattedString},
    "otherCost": {...defaults, show: true, headerClassName: "align_right fix_pdf_letter_spacing", className: "align_right", formatFunction: parseFloatAsFormattedString},
    "quantity": {...defaults, show: true, headerClassName: "align_right fix_pdf_letter_spacing", className: "align_right", formatFunction: parseFloatAsFormattedString},
    "roundUpTotalCost": {...defaults},
    "totalCost": {...defaults, show: true, headerClassName: "align_right fix_pdf_letter_spacing", className: "align_right", formatFunction: parseFloatAsFormattedString},
    "hasAssumedGroundTypes": {...defaults},
}

export default priceBookItemColumnDefinitions;
