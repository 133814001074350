import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const B2BLoading = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div className="text-center">
        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
        <h2 className="mt-1">Authentication in progress...</h2>
      </div>
    </div>
  );
};

export default B2BLoading;
