import React from "react"
import {
  Toast
} from "react-bootstrap";

interface IToastDefaults
{    
    'aria-live': 'off' | 'assertive' | 'polite';
    'aria-atomic': boolean;
    role: React.AriaRole;
    'toast-className'?: string;
    'header-className'?: string;
    'body-className'?: string;
}

interface IToastTitleDefaults extends IToastDefaults
{
    title: string;
}

const defaults: IToastDefaults =
{
    'aria-live': 'assertive',
    'aria-atomic': true,
    role: "alert",
}

const apiErrorMessageDefaults: IToastTitleDefaults =
{
    ...defaults,
    'toast-className': 'bg-warning',
    'header-className': 'text-dark',
    'body-className': 'text-dark',
    title: 'Error Submitting',
}

export const apiErrorMessageToToast: (message: string) => IToast = (message) => { return { ...apiErrorMessageDefaults, message: message}};

export const apiErrorMessagesToToast: (messages: string[]) => IToast[] = (messages) => messages.map<IToast>((value) => apiErrorMessageToToast(value)); 

export const fatalApiErrorToast: IToast = {
    ...defaults,
    'toast-className': 'bg-danger',
    'header-className': 'text-dark',
    'body-className': 'text-white',
    title: 'Error Submitting',
    message: 'A network error occurred while submitting. Please try again.'
};

export interface IToast extends IToastTitleDefaults
{
    message: string;
}


export interface IToastHandler
{
    toast: IToast[];
    removeToastCallback: (index: number) => void;
}

export default class ToastHandler extends React.Component<IToastHandler>
{
    constructor(props : IToastHandler)
    {
        super(props);
    }
    
    render = () =>
        <>
        <div
            aria-live="polite"
            aria-atomic="true"
            role="alert"
            style={{
            position: 'absolute',
            minHeight: '100px',
            top: 0,
            right: 0,
            zIndex: 100,
            }}
        >
            {this.props.toast.map((toast, index) =>
            <Toast
                key={`${toast.title}_${toast.message}`}
                aria-live={toast["aria-live"]}
                aria-atomic={toast["aria-atomic"]}
                role={toast.role}
                onClose={() => this.props.removeToastCallback(index)}
                className={toast["toast-className"]}
            >
                <Toast.Header className={toast["header-className"]}>
                    <strong className="mr-auto">{toast.title}</strong>
                </Toast.Header>
                <Toast.Body className={toast["body-className"]}>{toast.message}</Toast.Body>
            </Toast>
            )}
            
        </div>
        {this.props.children}
        </>
}