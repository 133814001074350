import { MsalProvider } from "@azure/msal-react";
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { msalConfig } from "../authConfig";

import MsalSwitcher from "../MsalSwitcher";

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
// const accounts = msalInstance.getAllAccounts();
// if (accounts.length > 0) {
//   msalInstance.setActiveAccount(accounts[0]);
// }

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
  if( event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    console.log("token failed, re-login...");
    msalInstance.loginRedirect();
  }
});

const B2BApp = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <MsalSwitcher />
    </MsalProvider>
  );
};

export default B2BApp;
