import { Col, Container, Form, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import RestartButton from "../RestartButton";
import { useEffect, useState } from "react";


const { REACT_APP_MAP_HOST_HEATMAP: maphost } = process.env;


export interface IStudyFormProps {
    ResetCallback: () => void,
  }


  type modes = "conductors" | "transformers";
  type percentageCalculations = "sector" | "region";

const Heatmap =  (props: IStudyFormProps) => {

  const [mode, setMode] = useState<modes>("conductors");
  const [percentageCalculation, setPercentageCalculation] = useState<percentageCalculations>("sector");

  const onModeChange : React.ChangeEventHandler<HTMLInputElement> = ({target: {value}}) => {
    setMode(value as modes);
  };

  const onPercentageCalculationChange : React.ChangeEventHandler<HTMLInputElement> = ({target: {value}}) => {
    setPercentageCalculation(value as percentageCalculations);
  };

  useEffect(() => {
    let frame = document.getElementById("connectlite");
    if (isIFrame(frame) && frame.contentWindow) {
      //console.log("Sending inputs to frame");
      //console.log(inputs);
      frame.contentWindow.postMessage({mode}, "*");
    }
  }, [mode]);

  useEffect(() => {
    let frame = document.getElementById("connectlite");
    if (isIFrame(frame) && frame.contentWindow) {
      //console.log("Sending inputs to frame");
      //console.log(inputs);
      frame.contentWindow.postMessage({percentageCalculation}, "*");
    }
  }, [percentageCalculation]);

  const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === "IFRAME";


    const resetAndTriggerCallback = async () => {    
        if (props.ResetCallback) {
          props.ResetCallback();
        }
      }

    return <Container>
            <Row className="my-4">
                <Col xs={12} md={4} lg={3} className="mb-2 mb-md-0">
                  <div><label htmlFor={"mode"}>Grey Asset Type</label></div>
                  <ToggleButtonGroup
                      vertical={true}
                      id={"mode"}
                      name={"mode"}
                      title="Grey Asset Type"
                      value={mode}>
                        <ToggleButton 
                          value={"conductors"}
                          title={"Conductors"}
                          onChange={onModeChange}>
                            Conductors
                        </ToggleButton>
                        <ToggleButton 
                          value={"transformers"}
                          title={"Transformers"}
                          onChange={onModeChange}>
                            Transformers
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Col>
                <Col xs={12} md={4} lg={3} className="mb-2 mb-md-0 border-top border-md-top-0 border-md-left">
                  <div><label htmlFor={"percentageCalculation"}>Percentage Calculation</label></div>
                  <ToggleButtonGroup
                      vertical={true}
                      id={"percentageCalculation"}
                      name={"percentageCalculation"}
                      title="Percentage Calulation"
                      value={percentageCalculation}>
                        <ToggleButton 
                          value={"sector"}
                          title={"Within Postcode Sector"}
                          onChange={onPercentageCalculationChange}>
                            Within Postcode Sector
                        </ToggleButton>
                        <ToggleButton 
                          value={"region"}
                          title={"Across Entire NI Region"}
                          onChange={onPercentageCalculationChange}>
                            Across Entire NI Region
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Col>
                <Col xs={12} md={{span: 2, offset: 2}} lg={{span: 2, offset: 4}} className="pt-2 pt-md-0 border-top border-md-top-0 border-md-left">
                  <RestartButton
                    RestartCallback={resetAndTriggerCallback}
                    ButtonVariant={"outline-secondary"}
                    ButtonSize="sm"
                    ButtonDisplayBlock={true}
                    ConfirmWithModal={false}
                  />
                </Col>
            </Row>
            <Row>
                <Col className="mb-4 border" style={{ height: "500px" }}>
                  <iframe
                    title="connectlite"
                    id="connectlite"
                    src={maphost}
                    frameBorder="0"
                    style={{
                      zIndex: 1,
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      top: "0px",
                      left: "0px",
                      border: "none",
                    }}
                    allowFullScreen
                    allow="clipboard-write"
                  ></iframe>
                </Col>
              </Row> 
        </Container>
}
export default Heatmap;