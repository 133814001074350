import { configureStore, ThunkAction, Action, combineReducers, CombinedState, } from "@reduxjs/toolkit";
import appReducer, { AppState, initialState } from "./appSlice";

import { persistStore, persistReducer, PersistConfig } from 'redux-persist'
import expireReducer, { ExpireReducerConfig }  from 'redux-persist-expire'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const { REACT_APP_USE_LOCAL_STORAGE, REACT_APP_LOCAL_STORAGE_EXPIRY_SECONDS } = process.env;
const shouldUseLocalStorage = REACT_APP_USE_LOCAL_STORAGE === "true";
const expirySeconds = parseInt(REACT_APP_LOCAL_STORAGE_EXPIRY_SECONDS as string);

const expireReducerConfig : ExpireReducerConfig = {
  expireSeconds: expirySeconds,
  persistedAtKey: "_persistedAt",
  autoExpire: true,
  expiredState: initialState,
};

const persistConfig : PersistConfig<CombinedState<{app: AppState}>> = {
  key: 'ConnectLite.Internal.AppState',
  storage,
  transforms: [
    expireReducer("app", expireReducerConfig),   
  ]
}

const rootReducer = combineReducers({app: appReducer});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

var options: any = {  manualPersist: true };

export const persistor = persistStore(store, options );

if (shouldUseLocalStorage) {
  persistor.persist();
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
